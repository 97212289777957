import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from '../components/PrivateRoute';
import ReferralsPage from '../components/pageComponents/ReferralsPage';

export default ({ pageContext }) => {
    return (
        <Router basepath="/referrals">
            <PrivateRoute component={ReferralsPage} path="/" />
        </Router>
    )
}