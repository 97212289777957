import React from 'react';
import { getLocalStorage, AUTH_USER } from '../utils';
import { navigate } from 'gatsby';

export default ({component: Component, location, ...rest}) => {
    if (typeof window !== "undefined") {
        if (!getLocalStorage(AUTH_USER) && location.pathname !== `/login`) {
            navigate('/login', { state: { redirectTo: location.pathname } });
            return null;
        }
        return <Component {...rest} location={location} />
    }
    return null;
}