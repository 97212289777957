import React from 'react';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Formik, FieldArray, Form, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import Layout, { AuthContext } from '../Layout';
import Dialog from '../Dialog';
import Icon from '../Icon';
import { getAxiosErrorMessage, postWithToken } from '../../utils';
import { BASE_PATH } from '../../config';
import { FOOD_ROUTE, FARM_ROUTE, EMAIL_REGEX } from '../../constants';

const EMAIL_MAX = 10;

const SuccessDialog = ({ dialogOpen, setDialogOpen, resultData: { newEmails, exceptions } }) => {
    function handleClose() {
        setDialogOpen(false);
    }
    return (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            maxWidth={500}
        >
            <div className='result-dialog-container'>
                <button className='btn close-btn' onClick={handleClose}>
                    <Icon iconName='times' width={18} />
                </button>
                {newEmails.length > 0 && (
                    <>
                        <div className="icon-container">
                            <Icon iconName={"check"} width={60} />
                        </div>
                        <p>
                            Success! We received your referral submissions. We have sent referral notifications to the following emails:
                        </p>
                        <div className="margin-bottom-2">
                            {newEmails.map(email => {
                                return (
                                    <Grid container alignItems='center' key={email} spacing={1}>
                                        <Grid item><div className="text-primary flex-center"><Icon width={16} iconName="check" /></div></Grid>
                                        <Grid item xs>{email}</Grid>
                                    </Grid>
                                )
                            })}
                        </div>
                    </>
                )}
                {exceptions.length > 0 && (
                    <>
                        <p>The following exceptions occurred:</p>
                        <div className="margin-bottom-2">
                            {exceptions.map((exception, index) => {
                                return (
                                    <Grid container alignItems='center' key={`exception-${index}`} spacing={1}>
                                        <Grid item><div className="text-error flex-center"><Icon width={16} iconName="times" /></div></Grid>
                                        <Grid item xs>{exception}</Grid>
                                    </Grid>
                                )
                            })}
                        </div>
                    </>
                )}
                <p>We're very appreciative of your support! Feel free to continue exploring our site, or submit more referrals if you like!</p>
                <div className='btn-container grid-container add-width-1'>
                    <div className='grid-item-2 add-padding'>
                        <Link to={FARM_ROUTE} className='btn white-btn'>SEARCH FOR FARMS</Link>
                    </div>
                    <div className='grid-item-2 add-padding'>
                        <Link to={FOOD_ROUTE} className='btn primary-btn'>SEARCH FOR FOOD</Link>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

const ReferralsPage = ({ addSnackbarMessage }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [resultData, setResultData] = React.useState({ newEmails: [], exceptions: [] });
    const user = React.useContext(AuthContext);
    const imageData = useStaticQuery(graphql`
    query {
        referrals: file(relativePath: { eq: "referrals.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }`)
    const shareableLink = user ? `https://usetill.com/signup/?ruid=${user.uid}` : '';
    async function handleCopy() {
        if (user && typeof window !== "undefined") {
            await window.navigator.clipboard.writeText(shareableLink);
            addSnackbarMessage('Copied to clipboard!')
        }
    }
    return (
        <div className="referral-body">
            <div className="referral-container">
                <div className="grid-container">
                    <div className="grid-item-2">
                        <div className="image-container">
                            <GatsbyImage fluid={imageData.referrals.childImageSharp.fluid} style={{height: '100%'}} objectPosition="50% 0" />
                            <div className="overlay text-center">
                                <h2 className="margin-bottom-2">Give $15, get $15</h2>
                                <p>As a till member you can refer a friend to till and earn $15 over and over!</p>
                                <div className="referral-steps">
                                    <h6>STEP 1</h6>
                                    <p>Send referrals to your friends</p>
                                    <h6>STEP 2</h6>
                                    <p>Your friend shops with till</p>
                                    <h6>STEP 3</h6>
                                    <p>You both get $15 off!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-item-2">
                        <div className="add-padding-2">
                            <Formik
                                initialValues={{ emails: [''] }}
                                validate={({ emails }) => {
                                    const errors = {};
                                    emails.forEach((value, index) => {
                                        if (EMAIL_REGEX.test(value) === false) {
                                            errors[`emails.${index}`] = "This email address is invalid";
                                        }
                                    })
                                    return errors;
                                }}
                                onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
                                    setStatus(null);
                                    postWithToken(user.getIdToken(), `${BASE_PATH}/sendEmailReferrals`, values)
                                    .then(({ data }) => {
                                        setResultData(data);
                                        resetForm();
                                        setDialogOpen(true);
                                    })
                                    .catch((err) => {
                                        setStatus(getAxiosErrorMessage(err));
                                        setSubmitting(false);
                                    });
                                }}
                            >
                                {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, status, errors, touched }) => {
                                    function renderStatus() {
                                        if (status) {
                                            return <div className='status-container text-center error'><span>{status}</span></div>
                                        }
                                        return undefined;
                                    }
                                    if (!user) {
                                        return null;
                                    }
                                    return (
                                        <>
                                            {renderStatus()}
                                            <h3 className="margin-bottom-2">Be a local hero! Invite your friends to use till.</h3>
                                            <div className="margin-bottom-1"><h6>Share your unique link</h6></div>
                                            <input
                                                className='form-input'
                                                type='text'
                                                value={shareableLink}
                                                readOnly
                                            />
                                            <div className="margin-bottom-2">
                                                <Grid container>
                                                    <Grid item>
                                                        <button className={`btn primary-btn grid-btn small-btn`} type="button" onClick={handleCopy}>
                                                            <span><Icon iconName="copy" width={16} /></span>
                                                            <span>COPY LINK</span>
                                                        </button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="margin-bottom-2">
                                                <p className="caption">After copying your unique link, you can text it, email it and share it on social media!</p>
                                            </div>
                                            <div className="margin-bottom-2">
                                                <div className="referral-or-container">
                                                    <div className="line-item"><span /></div>
                                                    <div className='content'>OR</div>
                                                    <div className="line-item"><span /></div>
                                                </div>
                                            </div>
                                            <Form>
                                                <div className="margin-bottom-1"><h6>Share via email <span className="text-muted">(we’ll send your friends your unique link automatically - just enter their email addresses below)</span></h6></div>
                                                <FieldArray 
                                                    name="emails"
                                                    render={arrayHelpers => {
                                                        function handleAddClick() {
                                                            arrayHelpers.push('')
                                                        }
                                                        return (
                                                            <>
                                                                <div className="margin-bottom-2">
                                                                    {values.emails.map((email, index) => {
                                                                        function handleRemove() {
                                                                            arrayHelpers.remove(index);
                                                                        }
                                                                        return (
                                                                            <Field name={`emails.${index}`} key={`email-${index}`}>
                                                                                {({ field, meta }) => {
                                                                                    const hasError = meta.touched && errors[`emails.${index}`];
                                                                                    return (
                                                                                        <>
                                                                                            <Grid container spacing={1} alignItems="center">
                                                                                                <Grid item xs>
                                                                                                    <input
                                                                                                        {...field}
                                                                                                        className={`form-input${hasError ? ' invalid' : ''}`}
                                                                                                        type='email'
                                                                                                        placeholder={`Email #${index+1}`}
                                                                                                    />
                                                                                                </Grid>
                                                                                                {values.emails.length > 1 && <Grid item>
                                                                                                    <button className="btn remove-item-btn" onClick={handleRemove} aria-label="Remove email"><Icon iconName="times" width={14} /></button>
                                                                                                </Grid>}
                                                                                            </Grid>
                                                                                            {hasError && <div className="error-msg">{errors[`emails.${index}`]}</div>}
                                                                                        </>
                                                                                    )
                                                                                }}
                                                                            </Field>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <Grid container alignItems="center" justify='space-between' spacing={2}>
                                                                    <Grid item>
                                                                        <div>
                                                                            <button className={`btn white-btn grid-btn small-btn ${values.emails.length > EMAIL_MAX - 1 ? ' disabled' : ''}`} type="button" onClick={handleAddClick} disabled={Boolean(values.emails.length > EMAIL_MAX - 1)}>
                                                                                <span><Icon iconName="plus" width={16} /></span>
                                                                                <span>ADD EMAIL</span>
                                                                            </button>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <div>
                                                                            <button type='submit' className={`btn full-width small-btn primary-btn${isSubmitting ? ' disabled' : ''}`} disabled={isSubmitting}>
                                                                                {isSubmitting ? <span className='saving'>SUBMITTING<span>.</span><span>.</span><span>.</span></span> : <span>SUBMIT</span>}
                                                                            </button>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    }}
                                                />
                                            </Form>
                                        </>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <SuccessDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} resultData={resultData} />
        </div>
    )
}

export default () => {
    return (
        <Layout pageTitle='Referrals' addLayoutProps>
            <ReferralsPage />
        </Layout>
    )
}
